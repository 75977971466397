h1,h2,h3, p {
  margin: 0;
  text-align: center;
  color: white;
}

.deckBody {
  margin: 20px;
  width: 866px;
  min-height: 320px;

  $grid-width: 136px;
  $grid-height: 188px;

  display: grid;
  grid-template-columns: $grid-width $grid-width $grid-width $grid-width $grid-width $grid-width;
  grid-template-rows: $grid-height $grid-height;
  gap: 10px;
  background-color: transparent;
  padding: 10px;
}

button.restackCards {
  grid-area: 1 / 1;
  border: 4px solid transparent;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-self: center;
  align-self: center;
  background-color: yellow;
  opacity: 0.9;
}

$opacity: 0.1;
.stack_sign {
  // background-color: rgba(255, 255, 255, 0.2);
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, $opacity), rgba(255, 255, 255, 0));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, $opacity), rgba(255, 255, 255, 0));
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(255, 255, 255, $opacity)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, $opacity), rgba(255, 255, 255, 0));
  width: 136px;
  height: 100%;
  border-radius: 10px;
}

.stack_1 {
  grid-area: 1 / 1;
}

.stack_2 {
  grid-area: 1 / 2;
}

.stack_3 {
  background-color: unset !important;
  background-image: unset !important;
  grid-area: 1 / 3;
}

.stack_4 {
  grid-area: 1 / 4;
}

.stack_5 {
  grid-area: 1 / 5;
}

.stack_6 {
  grid-area: 1 / 6;
}

.stack_7 {
  grid-area: 1 / 7;
}

.deck_pos_1 {
  grid-area: 2 / 1;
}
.deck_pos_2 {
  grid-area: 2 / 2;
}

.deck_pos_3 {
  grid-area: 2 / 3;
}

.deck_pos_4 {
  grid-area: 2 / 4;
}

.deck_pos_5 {
  grid-area: 2 / 5;
}

.deck_pos_6 {
  grid-area: 2 / 6;
}

.deck_pos_7 {
  grid-area: 2 / 7;
}
