.cardBody_default {
  border: 4px solid transparent;
  border-radius: 10px;
  margin: 10px;
}

.flipped {
  // animation class
}

.cardBody_0 {
  display: flex;
  justify-self: center;
  align-self: center;
} 

.cardBody_1 {
  padding: 6px 7px 0px 7px;
  position: absolute;
  z-index: 99999 !important;
}


/////////////__________________
