.app {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(15, 29, 16);
  background-blend-mode: color-dodge;
  box-shadow: inset 0px 0px 150px black;
  background-size: 176px;
  background-repeat: repeat;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Oxygen', sans-serif;
}

h1 {
  margin-top: 15px;
  font-size: 19px;
}